import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import { useProfileServiceGetOrganisation, useProfileServiceGetUser } from '@/api/ui/queries';
import { authNamespace } from '../Authentication/Auth0ProviderSmartMooring';
import { CustomClaims } from '../Authentication/Auth0Types';
import SmartMooringContext, { SmartMooringContextInterface } from './SmartMooringContext';

export interface SmartMooringProviderOptions {
  children?: React.ReactNode;
  context?: React.Context<SmartMooringContextInterface>;
}

export const localStorageOrganisationIdKey = 'selectedOrganisationId';
const getLocalStorageOrganisationId = () => localStorage.getItem(localStorageOrganisationIdKey);

const profileQueryOptions = (enabled: boolean) => ({
  enabled,
  refetchOnWindowFocus: false,
  refetchOnMount: false,
});

const SmartMooringProvider = (opts: SmartMooringProviderOptions): JSX.Element => {
  const { children, context = SmartMooringContext } = opts;
  const navigate = useNavigate();
  const { user: auth0User } = useAuth0();
  const auth0UserProfile: CustomClaims | undefined = auth0User?.[authNamespace];
  const [selectedOrganisationId, setSelectedOrganisationId] = useState(getLocalStorageOrganisationId());
  const [pageTitle, setPageTitle] = useState('');
  const [pageTitleIcon, setPageTitleIcon] = useState<ReactElement | undefined>(undefined);
  const isQueryEnabled = !!selectedOrganisationId && !!auth0UserProfile;

  const { data: selectedOrganisation } = useProfileServiceGetOrganisation(
    { xSelectedOrganisationId: selectedOrganisationId },
    [selectedOrganisationId],
    profileQueryOptions(isQueryEnabled)
  );
  const { data: userProfile } = useProfileServiceGetUser([selectedOrganisationId], profileQueryOptions(isQueryEnabled));

  useEffect(() => {
    if (!auth0UserProfile) return;
    if (selectedOrganisationId) return;

    // If selectedOrganisationId is not set, set it to the organisation_id from the user profile.
    const auth0OrganisationId = auth0UserProfile?.organization_id;
    if (auth0OrganisationId) {
      setSelectedOrganisationId(() => auth0OrganisationId);
      localStorage.setItem(localStorageOrganisationIdKey, auth0OrganisationId);
    }
  }, [auth0UserProfile, selectedOrganisationId]);

  useEffect(() => {
    if (!userProfile) return;
    if (!auth0UserProfile) return;
    if (!selectedOrganisationId) return;
    if (!userProfile?.allowedOrganisations?.some((x) => x.organisationId == selectedOrganisationId)) {
      localStorage.removeItem(localStorageOrganisationIdKey);
      return;
    }

    localStorage.setItem(localStorageOrganisationIdKey, selectedOrganisationId);
  }, [auth0UserProfile, selectedOrganisationId, userProfile]);

  const switchOrganisation = useCallback(
    (orgId: string) => {
      setSelectedOrganisationId(orgId);
      navigate('/');
    },
    [setSelectedOrganisationId, navigate]
  );

  if (isQueryEnabled && (!selectedOrganisation || !userProfile)) return <></>;

  return (
    <context.Provider
      value={{
        switchOrganisation,
        selectedOrganisationId,
        selectedOrganisation: selectedOrganisation,
        allowedOrganisations: userProfile?.allowedOrganisations ?? [],
        pageTitle,
        setPageTitle,
        pageTitleIcon,
        setPageTitleIcon,
      }}
    >
      {children}
    </context.Provider>
  );
};

export default SmartMooringProvider;
